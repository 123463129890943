
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import variables from "@/router/api";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return { passwordFieldType: "password", showPassword: false };
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      emailAddress: Yup.string()
        .email()
        .required()
        .label("Email"),
      password: Yup.string()
        .min(8)
        .required()
        .label("Password"),
    });

    const signOut = () => {
      ApiService.setHeader();
      ApiService.post(variables.LOGOUT_ROUTE, "").then((response) => {
        variables.toastAlert(response.data.message, "success");
        store.dispatch(Actions.LOGOUT).then(() => {
          // router.push({ name: "underwriter-sign-in" });
          window.location.reload();
        });
      });
    };

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          variables.toastAlert("Login was successful", "success");

          // Redirect to page after successfully login
          const user = store.getters.currentUser;

          // Page redirects for Individual Client
          if (user.role == variables.UNDERWRITER_USER_ROLE) {
            router.push({ name: "underwriter-dashboard" });
          } else {
            return signOut();
          }
        })
        .catch(() => {
          if (store.getters.getErrors == variables.EXPIRED_JWT_TOKEN) {
            window.location.reload();
          } else {
            variables.toastAlert(store.getters.getErrors, "error");
          }
        });
      // Dummy delay
      setTimeout(() => {
        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      signOut,
    };
  },
  created() {
    document.title = "Underwriter Login | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.showPassword = !this.showPassword;
    },
  },
});
